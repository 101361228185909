<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
        <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
      </a-col>
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%" @change="search">
          <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <client-select v-model="searchForm.client" placeholder="客户" @change="search"></client-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
        <a-input v-model="searchForm.search" placeholder="单据编号, 客户单号, 备注" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
        <a-button type="primary" icon="search" @click="search">查询</a-button>
      </a-col>
    </a-row>

    <div>
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :data-source="items"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1260, y: 480 }"
        @change="onChangeTable"
      >
        <template slot="receipt_order_number" slot-scope="value, item, index">
          <a @click="detail(item)">{{ value }}</a>
        </template>
        <div slot="action" slot-scope="value, item, index">
          <a-button-group size="small">
            <a-button @click="detail(item)">详情</a-button>
            <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
              <a-button type="danger">作废</a-button>
            </a-popconfirm>
          </a-button-group>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import { receiptRecordList, receiptRecordVoid } from "@/api/stockIn";
import { warehouseOption } from "@/api/option";
import { columns } from "./columns";
import moment from 'moment'

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      searchForm: { page: 1, is_void: false },
      loading: false,
      items: [],

      warehouseItems: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = {...this.searchForm};
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      receiptRecordList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    voidItem(item) {
      receiptRecordVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.list();
      });
    },
    detail(item) {
      this.$router.push({ path: "/stock_in/receipt_record_detail", query: { id: item.id } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
