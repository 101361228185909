export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: '入库通知单号',
    dataIndex: 'receipt_order_number',
    sorter: true,
    fixed: "left",
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: 'receipt_order_number' },
  },
  {
    title: '仓库名称',
    dataIndex: 'warehouse_name',
    width: 120,
    ellipsis: true,
 },
  {
    title: '客户',
    dataIndex: 'client_name',
    width: 120,
    ellipsis: true,
 },
  {
    title: '操作人',
    dataIndex: 'creator_name',
    width: 120,
    ellipsis: true,
 },
  {
    title: '单据创建人',
    dataIndex: 'order_creator_name',
    width: 120,
    ellipsis: true,
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 240,
    ellipsis: true,
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: "right",
    width: 120,
    ellipsis: true,
 },
]